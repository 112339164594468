import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import dayjs from "dayjs";
import {
    Document,
    Text,
    Page,
    View,
    StyleSheet,
    Font,
} from "@react-pdf/renderer";
import prettyDate from "../../../formatdate";

export default function IncompleteInspectionsReport({
    inspectionsData,
    datesRange,
}) {
    // console.log("inspectionsData", inspectionsData);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const fullMonths = {
        Jan: "January",
        Feb: "February",
        Mar: "March",
        Apr: "April",
        May: "May",
        Jun: "June",
        Jul: "July",
        Aug: "August",
        Sep: "September",
        Oct: "October",
        Nov: "November",
        Dec: "December",
    };

    const styles = StyleSheet.create({
        page: {
            fontFamily: "Helvetica",
            fontSize: 11,
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 60,
            paddingRight: 60,
            lineHeight: 1.5,
            flexDirection: "column",
        },
        // ********************************* Header Details *********************************
        titleContainer: {
            backgroundColor: colors.grey[900],
            flexDirection: "row",
            marginTop: 15,
            marginBottom: 15,
        },
        logTitle: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 5,
            letterSpacing: 1,
            fontSize: 12,
        },
        hr: {
            backgroundColor: colors.grey[600],
            border: "0.5px solid " + colors.grey[700],
            marginBottom: 10,
        },
        thick_hr: {
            backgroundColor: colors.grey[600],
            border: "3px solid " + colors.grey[700],
        },
        // ********************************* Requirements *********************************
        logContainer: {
            display: "flex",
            marginTop: 24,
        },
        surveyItem: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            marginBottom: 15,
        },
        surveyItemTitle: {
            flex: "0 0 65%",
            fontWeight: "bold",
        },
        surveyItemValue: {
            flex: "0 0 35%",
            color: colors.grey[600],
        },
        requirementsStatus: {
            width: "40%",
            fontSize: 9,
        },

        tableStyle: {
            display: "table",
            width: "auto",
        },
        tableRowStyle: {
            flexDirection: "row",
        },
        dateTableColStyle: {
            width: "20%",
        },
        detailsTableColStyle: {
            width: "60%",
        },
        ipTableColStyle: {
            width: "20%",
        },
        tableCellStyle: {
            margin: 5,
            fontSize: 10,
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        },
    });

    return (
        <Document title="Incomplete requirements inspections reports">
            <Page size="A4" style={styles.page}>
                <View style={{ marginTop: 10, flexDirection: "column" }}>
                    <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                        Occusurv portal
                    </Text>
                    <Text style={{ alignSelf: "flex-end" }}>
                        Generated on: {prettyDate(new Date().toISOString())}
                    </Text>
                </View>
                <View style={styles.surveyItem}>
                    <Text style={styles.surveyItemTitle}>
                        Report data dated:{" "}
                        {dayjs(datesRange?.[0]).format("YYYY-MM-DD")} -{" "}
                        {dayjs(datesRange?.[1]).format("YYYY-MM-DD")}
                    </Text>
                </View>

                {/* Loop through each month */}
                {Object.entries(inspectionsData)?.map(([month, inspections]) => (
                    <View key={month}>
                        <View style={styles.titleContainer}>
                            <Text style={styles.logTitle}>
                                {`Completed Requirements Inspections for the month of: ${fullMonths[month]}`}
                            </Text>
                        </View>

                        {/* Loop through each inspection for the month */}
                        {inspections?.map((inspection, index) => (
                            //* *********************** Details of each inspection ***********************
                            <View key={inspection.audit_id} break={index > 0 ? true : false}>
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Policy Number:
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {inspection.audit_policy_number}
                                    </Text>
                                </View>
                                <View style={styles.hr}></View>
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Policy Holder:{" "}
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {inspection.audit_policy_holder}
                                    </Text>
                                </View>
                                <View style={styles.hr}></View>
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Broker:{" "}
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {inspection.audit_broker_name}
                                    </Text>
                                </View>
                                <View style={styles.hr}></View>
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Requirements loaded to portal on:{" "}
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {inspection.reminder_start_date}
                                    </Text>
                                </View>
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Difference between upload and requirements delivery dates:{" "}
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {dayjs(inspection.reminder_start_date).diff(dayjs(inspection.created_at), "day")} days
                                    </Text>
                                </View>
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Email reminder period:{" "}
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {inspection.reminder_days} days
                                    </Text>
                                </View>
                                {/* <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Broker's Initial Response:{" "}
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {`NOT Implemented - Date(num days)`}
                                    </Text>
                                </View> */}
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Inspection requirements completed on:{" "}
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {dayjs(inspection.updated_at).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </Text>
                                </View>
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Days to complete all requirements:{" "}
                                    </Text>
                                    <Text style={styles.surveyItemValue}>
                                        {" "}
                                        {dayjs(inspection.updated_at).diff(
                                            dayjs(
                                                inspection.reminder_start_date
                                            ),
                                            "day"
                                        )} days
                                    </Text>
                                </View>
                                <View style={styles.hr}></View>
                                {/* ***************************** Requirements Summary **************************** */}
                                <View style={styles.surveyItem}>
                                    <Text style={styles.surveyItemTitle}>
                                        {" "}
                                        Requirements summary:{" "}
                                    </Text>
                                </View>
                                <View style={styles.surveyItem}>
                                    <Text style={styles.requirementsStatus}>
                                        {" "}
                                        Requirements selected:{" "}
                                    </Text>
                                    <Text style={styles.requirementsStatus}>
                                        {" "}
                                        {inspection.total_selected_requirements}
                                    </Text>
                                    <Text style={styles.requirementsStatus}>
                                        {" "}
                                        Requirements not selected:{" "}
                                    </Text>
                                    <Text style={styles.requirementsStatus}>
                                        {" "}
                                        {inspection.total_not_selected}
                                    </Text>
                                    <Text style={styles.requirementsStatus}>
                                        {" "}
                                        Requirements marked completed:{" "}
                                    </Text>
                                    <Text style={styles.requirementsStatus}>
                                        {" "}
                                        {inspection.completed}
                                    </Text>
                                    <Text style={styles.requirementsStatus}>
                                        {" "}
                                        Requirements marked waived:{" "}
                                    </Text>
                                    <Text style={styles.requirementsStatus}>
                                        {" "}
                                        {inspection.waived}
                                    </Text>
                                </View>
                                <View style={styles.hr}></View>
                                {/* Create extra space */}
                                <View style={{ marginBottom: 10 }}></View>
                            </View>
                        ))}
                    </View>
                ))}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
}
